import React from 'react';
import PulzonText from '../images/pulzon-text.inline.svg';
import Logo from '../images/logo.inline.svg';

import style from './header.module.scss';

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.logo}>
        <Logo className={style.logoIcon} />
        <PulzonText className={style.logoText} />
      </div>
      <div className={style.menu}>
        <a href="https://app.pulzon.com/login">
          <button type="button" className={style.signInButton}>
            Sign in
          </button>
        </a>
      </div>
    </header>
  );
};

export default Header;
