/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import style from './layout.module.scss';

const Layout = ({ children }) => {
  return (
    <>
      <div className={style.mainContainer}>
        <Header />
        <main className={style.pageContainer}>{children}</main>
        <footer className={style.footer}>
          <h3>
            IMPORTANT: This website is under construction and it just works with dummy data. Feel free to click around
            and give me a feedback.
          </h3>
          <p>
            Contact: <a href="mailto:pulzon.info@gmail.com">pulzon.info@gmail.com</a>
          </p>
          <p>
            © {new Date().getFullYear()}, Designed and built by <a href="https://norbertbartos.tech">Norbert Bartos</a>
          </p>
          <a href="https://app.pulzon.com/privacyAndPolicy.html">Privacy policy</a>
          <p className={style.credits}>
            <a href="https://www.freepik.com/free-photos-vectors/people">
              People vector created by freepik - www.freepik.com
            </a>
          </p>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
